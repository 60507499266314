<template>
  <div class="page-wrapper page-result">
    <!-- Home Hero -->
    <section class="hero fluid-container">
      <div v-if="result" class="hero-wrapper max-container">
        <p class="small-text breadcrumbs">
          <span
            >{{ metiers[metier] }} / {{ statuts[statut] }} /
            {{ syndicats[syndicat] }}</span
          >
          <button class="restart-btn" @click.prevent="reset()">
            <span @click.prevent="reset()">Recommencez</span>
            <svg
              width="11"
              height="13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click.prevent="reset()"
            >
              <path
                d="M5.844 2.739H2.345l1.581-1.566a.685.685 0 0 0 0-.972.693.693 0 0 0-.976 0L.2 2.937a.681.681 0 0 0 0 .965l2.75 2.736a.69.69 0 0 0 .973.004c.27-.266.27-.7.003-.968l-1.58-1.567h3.498c1.003 0 1.965.397 2.674 1.102a3.753 3.753 0 0 1 0 5.321 3.79 3.79 0 0 1-2.674 1.102 3.789 3.789 0 0 1-2.673-1.102 3.753 3.753 0 0 1-1.108-2.66.686.686 0 0 0-.687-.685.686.686 0 0 0-.688.685c0 1.36.543 2.665 1.51 3.627a5.17 5.17 0 0 0 7.292 0A5.117 5.117 0 0 0 11 7.87c0-1.36-.543-2.666-1.51-3.628a5.169 5.169 0 0 0-3.646-1.503z"
                fill="#353535"
              />
            </svg>
          </button>
        </p>

        <!-- <div
          v-if="
            metier == 'briqueteur-macon' &&
            statut == 'compagnon' &&
            syndicat == 'FTQ'
          "
          class="vcenter"
        >
          <h1 class="big-title hero-title">
            En rejoignant le SQC, <br />
            vous pourriez économiser au minimum
            <strong
              >192,00 <sup>$<small>*</small></sup></strong
            >.
          </h1>
          <p class="regular-text">
            <small
              >Calcul basé sur 4 années de 36 semaines de 40 h de travail dans
              le secteur institutionnel et commercial, soit 1440 h/année.</small
            >
          </p>
        </div> -->

        <!-- <div
          v-if="
            metier == 'briqueteur-macon' &&
            statut.indexOf('apprenti') !== -1 &&
            syndicat == 'FTQ'
          "
          class="vcenter"
        >
          <h1 class="big-title hero-title">
            En rejoignant le SQC, <br />
            vous pourriez économiser au minimum
            <strong
              >6,40 <sup>$<small>*</small></sup></strong
            >.
          </h1>
          <p class="regular-text">
            <small
              >Calcul basé sur 4 années de 34 semaines de 40 h de travail dans
              le secteur institutionnel et commercial, soit 1360 h/année.</small
            >
          </p>
        </div> -->

        <div v-if="syndicat === 'SQC'" class="vcenter">
          <h1 class="big-title hero-title">
            Vous faites déjà partie de <strong>l’équipe gagnante</strong> <br />
            en payant une cotisation juste.
          </h1>
        </div>

        <div v-else-if="result > 0" class="vcenter">
          <h1 class="big-title hero-title">
            En rejoignant l’équipe SQC, <br />
            vous pourriez économiser
          </h1>
          <h2 class="big-title hero-price">
            + De
            {{ ((result * 100) / 100).toFixed(2).toString().replace('.', ',')
            }}<sup>$<small>*</small></sup>
          </h2>
        </div>

        <div v-else class="vcenter">
          <h2 class="big-title hero-price is-small">
            Pour
            {{
              ((((result * -1) / 40) * 100) / 100)
                .toFixed(2)
                .toString()
                .replace('.', ',')
            }}<sup>$</sup> de plus par semaine
          </h2>
          <h1 class="big-title hero-title is-small">
            Rejoignez le SQC aujourd’hui pour profiter <br />
            d’avantages qui en valent la peine. <br />
            <small class="small-car"
              >Une équipe qui vous ressemble: représentants disponibles,
              services juridiques gratuits, <br />
              rabais sur notre boutique en ligne et bien plus encore!
            </small>
          </h1>
        </div>

        <div class="actions">
          <div class="btn" rel="noopener noreferer">maraudage.sqc.ca</div>
          <button
            v-clipboard:copy="'https://' + appDomain + '/'"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="copy-btn btn"
          >
            <span>Copier et partager</span>&nbsp;
            <svg
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.07 0H5.723c-1.066 0-1.93.865-1.93 1.93v1.863H1.93C.865 3.793 0 4.658 0 5.723V20.07C0 21.135.865 22 1.93 22h14.346c1.066 0 1.93-.865 1.93-1.93v-1.863h1.863c1.066 0 1.931-.865 1.931-1.93V1.93C22 .865 21.135 0 20.07 0zm-3.38 20.07a.414.414 0 0 1-.414.413H1.931a.414.414 0 0 1-.414-.414V5.724c0-.228.185-.414.414-.414h14.345c.228 0 .414.186.414.414v14.345zm3.793-3.794a.414.414 0 0 1-.414.414h-1.862V5.724c0-1.066-.865-1.93-1.93-1.93H5.31V1.93c0-.229.186-.414.414-.414h14.345c.229 0 .414.185.414.414v14.345z"
                fill="#fff"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="bg">
        <img
          class="background-visual"
          src="@/assets/img/hero-resultat.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
      </div>
    </section>

    <!-- Home Video -->
    <section class="block-video fluid-container">
      <div class="block-video-wrapper max-container">
        <img
          src="@/assets/img/video-home.jpg"
          alt="Syndicat Québécois Construction"
        />
        <!-- <a class="btn-play glightbox" href="https://vimeo.com/412943670"
          ><img
            class="background-visual"
            src="@/assets/img/icon-play-white.svg"
            alt="Play video"
        /></a> -->
      </div>
    </section>

    <!-- Terms -->
    <!-- <section class="block-terms is-alternate fluid-container">
      <div class="block-terms-wrapper max-container">
        <p class="terms-text regulat-text">
          Distanciation physique ou non, le SQC est toujours près de vous. Jetez
          un œil à nos meilleures images du début 2020.
        </p>
      </div>
    </section> -->

    <!-- Home arguments -->
    <section class="block-arguments fluid-container">
      <div class="block-arguments-wrapper max-container">
        <h2 class="arguments-title big-title">
          Pourquoi rejoindre l’équipe du SQC ?
        </h2>

        <div class="arguments-list flex-grid">
          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="110px"
                class="argument-icon"
                src="@/assets/img/icon-equipe-2.png"
                alt="L'équipe"
              />
            </div>
            <h3 class="argument-title medium-title">L’équipe</h3>
            <p class="argument-text regular-text">
              Nos représentants sont des gens de métier, comme vous. On vous
              comprend. On vous défend. C’est pourquoi on est aussi présent en
              chantier qu’aux tables de négociation.
            </p>
          </article>

          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="75px"
                class="argument-icon"
                src="@/assets/img/icon-services.svg"
                alt="Les services"
              />
            </div>
            <h3 class="argument-title medium-title">Les services</h3>
            <p class="argument-text regular-text">
              De la formation aux services juridiques gratuits en passant par la
              recherche d’emploi et la gestion de votre statut de compétences,
              on fait valoir vos droits à tous les niveaux pour vous aider à
              bâtir une carrière qui vous rendra fier.
            </p>
          </article>

          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="40px"
                class="argument-icon"
                src="@/assets/img/icon-cotisations.svg"
                alt="Les cotisations"
              />
            </div>
            <h3 class="argument-title medium-title">Les cotisations</h3>
            <p class="argument-text regular-text">
              Gérées efficacement, nos cotisations sont fixes chaque semaine,
              sans aucuns frais de l'heure travaillée et interrompues, si vous
              ne travaillez pas. Vous aurez alors accès aux services de nos
              avocats spécialisés autant en santé et sécurité qu'en relations du
              travail, en plus de bénéficier de tous nos autres services.
            </p>
          </article>

          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="40px"
                class="argument-icon"
                src="@/assets/img/icon-extras.svg"
                alt="Les extra"
              />
            </div>
            <h3 class="argument-title medium-title">Avantages exclusifs</h3>
            <p class="argument-text regular-text">
              Nos membres peuvent profiter d'une panoplie d'avantages, comme des
              rabais sur notre boutique en ligne, des offres spéciales et des
              promotions exclusives.
            </p>
          </article>
        </div>
      </div>
    </section>

    <!-- Home CTA -->
    <section class="block-cta fluid-container">
      <div class="block-cta-wrapper max-container">
        <article class="block-cta-content">
          <div class="icon-circle">
            <img
              class="cta-icon"
              src="@/assets/img/icon-calculator.svg"
              alt="Calculateur"
            />
          </div>
          <h2 class="cta-title big-title">
            Devenir membre SQC, <br />
            ça vaut le coût.
          </h2>
          <router-link
            :to="{ name: 'calculation-start' }"
            class="site-btn is-small"
            title="Comparer les cotisations"
            >Comparer les cotisations</router-link
          >
        </article>

        <div class="bg">
          <img
            class="background-visual"
            src="@/assets/img/devenir-membre.jpg"
            alt="Employés : Syndicat Québécois de la Construction"
          />
        </div>
      </div>
    </section>

    <!-- Home split -->
    <section class="block-split fluid-container">
      <div class="block-split-wrapper max-container">
        <div class="flex-grid">
          <div class="split-visual">
            <img
              src="@/assets/img/sqc-video-2.png"
              alt="Scrutin Syndical 2020"
            />

            <a class="btn-play glightbox" href="https://youtu.be/F8Xb-lKOYMA"
              ><img src="@/assets/img/icon-play-white.svg" alt="Play video"
            /></a>
          </div>
          <article class="split-content">
            <h2 class="split-title big-title">
              Voter en quelques étapes faciles
            </h2>
            <p class="split-text regular-text">
              Changer votre trajectoire et améliorer votre futur, ça ne prend
              qu’une minute. Regardez notre vidéo pour passer dans la bonne
              équipe.
            </p>
            <a
              href="https://www.ccq.org/fr-CA/loi-r20/relations-travail/scrutin-syndical)"
              class="site-btn is-small"
              title="En savoir plus"
              target="_blank"
              rel="noopener noreferer"
              >En savoir plus</a
            >
          </article>
        </div>
      </div>
    </section>

    <!-- Home Newsletter -->
    <section class="block-newsletter fluid-container">
      <div class="block-newsletter-wrapper max-container">
        <img
          src="@/assets/img/icon-newsletter.svg"
          alt="Newsletter"
          class="newsletter-icon"
        />
        <h2 class="newsletter-title big-title">
          Abonnez-vous à l’infolettre <br />
          pour rester au courant.
        </h2>
        <!-- Form Metier -->
        <!-- <form class="newsletter-form" action="" >
          <div class="input-row">
            <input type="email" name="newsletter" value="" placeholder="Entrez votre courriel" required>
            <button type="submit" class="site-btn">
              <img class="background-visual" src="@/assets/img/icon-arrow-right.svg" alt="Arrow right">
            </button>
          </div>
        </form> -->
        <a
          href="https://www.sqc.ca/infolettre/"
          class="site-btn is-small"
          target="_blank"
          rel="noopener noreferer"
          title="Abonnez-vous"
          >Abonnez-vous</a
        >
      </div>

      <div class="bg">
        <img
          class="background-visual"
          src="@/assets/img/construction-chantier.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
      </div>
    </section>

    <!-- Terms -->
    <section
      v-if="
        (result > 0 && syndicat !== 'SQC') ||
        (metier == 'briqueteur-macon' &&
          statut == 'compagnon' &&
          syndicat == 'FTQ') ||
        (metier == 'briqueteur-macon' &&
          statut.indexOf('apprenti') !== -1 &&
          syndicat == 'FTQ')
      "
      class="block-terms fluid-container"
    >
      <div class="block-terms-wrapper max-container">
        <p class="terms-text regulat-text">
          * Votre allégeance syndicale sera maintenue du 1<sup>er</sup>
          septembre 2024 au 31 août 2028. <br /><br />
          Calcul basé sur 4 années de 40 semaines de 40 heures de travail, au
          taux horaire (jour) du métier en vigueur le 1<sup>er</sup> mai 2024,
          dans le secteur institutionnel et commercial. <br /><br />
          Pour les associations syndicales concernées, le calcul est effectué à
          partir des taux de cotisation du local ayant la plus grande
          représentativité du métier.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GLightbox from 'glightbox'
import { isNil } from 'lodash'

import {
  metiers,
  metiersStatutLoop,
  statuts,
  syndicats,
  database,
  refs,
  refsNegativeNumber,
} from '@/misc/static-database'

import arrowDown from '@/assets/img/arrow-down.svg'

export default {
  head() {
    return {
      title: {
        inner: "Pourquoi rejoindre l'équipe du sqc",
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `${this.appTitle} Qu'il soit question de votre recherche d'emploi, de vos droits, de votre statut de compétence ou de votre formation, notre équipe a le profil parfait et les outils nécessaires pour déployer le plan de match gagnant.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        {
          n: 'twitter:description',
          c: `${this.appTitle} Qu'il soit question de votre recherche d'emploi, de vos droits, de votre statut de compétence ou de votre formation, notre équipe a le profil parfait et les outils nécessaires pour déployer le plan de match gagnant.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:description',
          content: `${this.appTitle} Qu'il soit question de votre recherche d'emploi, de vos droits, de votre statut de compétence ou de votre formation, notre équipe a le profil parfait et les outils nécessaires pour déployer le plan de match gagnant.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/syndicat-qc-construction.appspot.com/o/employes-syndicat-quebecois-de-la-construction-banner.jpg?alt=media&token=c13a215c-0aef-4212-b967-3b853c0ffddd',
        },
      ],
    }
  },
  data() {
    return {
      arrowDown,
      steps: 3,
      metiers,
      statuts,
      syndicats,
      metier: '',
      metiersStatutLoop,
      statut: '',
      syndicat: '',
      result: null,
      resultUrl: null,
    }
  },
  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route) && $route.params) {
          const { params } = $route
          if (params.metier) {
            console.log({ metier: params.metier })
            this.metier = params.metier
          } else {
            this.metier = ''
            this.result = null
          }

          if (params.statut) {
            console.log({ statut: params.statut })
            this.statut = params.statut
          } else {
            this.statut = ''
            this.result = null
          }

          if (params.syndicat) {
            console.log({ syndicat: params.syndicat })
            this.syndicat = params.syndicat
          } else {
            this.syndicat = ''
            this.result = null
          }

          console.log(
            'Imported',
            { metiers, statuts, syndicats, database, refs, metiersStatutLoop },
            metiersStatutLoop[this.metier]
          )

          if (this.metier && this.statut && this.syndicat) {
            this.getResult(this.metier, this.statut, this.syndicat)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getResult(metierSlug, statutSlug, syndicatSlug) {
      console.log({ metierSlug, statutSlug, syndicatSlug })
      const metier = this.metiers[metierSlug]
      const statut = this.statuts[statutSlug]
      const syndicat = syndicatSlug

      database.forEach((row) => {
        if (row.A && row.A === metier && row.B && row.B === statut) {
          if (row[refs[syndicat]]) {
            // If the number if negative we need to fetch value from another ref
            if (row[refs[syndicat]] < 0) {
              // Fetching the ref from negative source
              this.result = row[refsNegativeNumber[syndicat]]
            } else {
              // Fetching price from default ref
              this.result = row[refs[syndicat]]
            }

            this.resultUrl = window.location.href
            console.log('👉👉👉', this.result)
          }
        }
      })
    },
    copyUniqueURL(e) {
      e.preventDefault()
    },
    onCopy() {
      this.doAlert({ title: `Lien copié avec succès.`, type: 'success' })
    },
    onError() {
      this.doAlert({
        title:
          'Votre navigateur ne supporte pas cette option. Veuillez le faire manuellement.',
        type: 'error',
      })
    },
    reset() {
      this.metier = ''
      this.statut = ''
      this.syndicat = ''
      this.result = null
      this.$router.push({ name: 'calculation-start' })
    },
    ...mapActions('app', ['doAlert']),
  },
  mounted() {
    const lightbox = GLightbox()
    console.log({ lightbox })
  },
  computed: mapState('app', ['appTitle', 'appDomain']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/site-modules.scss';

.hero {
  position: relative;
  min-height: 635px;
  padding-top: 75px;

  @media (min-width: 769px) {
    padding-top: 35px;
    height: 45vw;
    min-height: 700px;
  }

  .hero-wrapper {
    position: relative;
    z-index: 2;
    padding: 20px 0;
    color: #fff;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > .vcenter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      width: 100%;
    }

    @media (min-width: 769px) {
      padding: 80px 0;
      text-align: left;
    }
  }

  .breadcrumbs {
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    button {
      margin-bottom: 8px;
    }

    span {
      display: block;
    }

    @media (min-width: 769px) {
      text-align: left;
      display: block;

      span {
        display: inline;
        margin-top: 0;
      }
    }
  }

  .hero-title {
    width: 100%;
    margin: 0;
    padding: 15px 0;
    line-height: 1.2;
    margin-bottom: 0;
    padding-bottom: 5px;
    position: relative;
    bottom: 0;
    font-size: 25px;

    strong {
      color: $color-accent1;

      small {
        color: #fff;
      }
    }

    @media (min-width: 769px) {
      font-size: 43px;
    }

    &.is-small {
      font-size: 26px;

      @media (min-width: 769px) {
        font-size: 36px;
      }
    }
  }

  .hero-price {
    color: $color-accent1;
    margin: 0;
    padding: 0;
    font-size: 35px;
    text-align: center;
    width: 100%;

    @media (min-width: 769px) {
      font-size: 90px;
      text-align: left;
    }

    &.is-small {
      margin-top: 15px;
      font-size: 26px;

      @media (min-width: 769px) {
        font-size: 44px;
        text-align: left;
      }
    }

    sup {
      position: relative;
      top: -20px;
      font-size: 50%;
      margin-left: 5px;
    }

    small {
      color: #fff;
    }
  }

  .restart-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    padding: 5px 7px;
    margin-left: 5px;
    border-radius: 4px;

    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  .actions {
    z-index: 5;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;

    font-family: $font-stack;

    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;

    @media (min-width: 769px) {
      width: auto;
      max-width: inherit;
      margin: 0;
    }

    @media (min-width: 769px) {
      position: absolute;
      right: 0;
      bottom: 135px;
    }

    .btn {
      width: 50%;
      padding: 10px 10px;
      margin-top: 35px;
      border-radius: 0;
      border: none;
      background: #fff;
      color: $dark;
      text-align: left;
      line-height: 1;
      vertical-align: center;

      font-size: 11px;

      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      @media (min-width: 769px) {
        width: auto;
        padding: 15px 25px;
        font-size: 12px;
      }

      &.copy-btn {
        display: flex;
        align-items: center;
        background: $dark;
        color: #fff;
        text-align: left;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        cursor: pointer;

        svg {
          margin-left: 10px;
          width: 12px;

          @media (min-width: 769px) {
            width: 15px;
          }
        }
      }
    }
  }

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($dark, 0.6);
      z-index: 1;
    }

    img {
      display: block;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
</style>
