const metiers = {
  'briqueteur-macon': 'Briqueteur-maçon',
  carreleur: 'Carreleur',
  'charpentier-menuisier': 'Charpentier-menuisier',
  'cimentier-applicateur': 'Cimentier-applicateur',
  couvreur: 'Couvreur',
  electricien: 'Électricien',
  'unstallateur-de-systeme-de-securite': 'Installateur de système de sécurité',
  ferblantier: 'Ferblantier',
  'monteur-mecanicien-vitrier': 'Monteur-mécanicien vitrier',
  'operateur-equip-lourd-classe-aa': 'Opérateur équip. lourd, classe AA',
  'operateur-pelles-classe-aa': 'Opérateur pelles, classe AA',
  peintre: 'Peintre',
  platrier: 'Plâtrier',
  'poseur-systemes-interieurs': 'Poseur systèmes intérieurs',
  'poseur-revetements-souples': 'Poseur revêtements souples',
  manoeuvre: 'Manoeuvre',
  'manoeuvre-specialise': 'Manoeuvre spécialisé',
  'manoeuvre-en-maconnerie': 'Manoeuvre en maçonnerie',
}

const metiersStatutLoop = {
  'briqueteur-macon': 3,
  carreleur: 3,
  'charpentier-menuisier': 3,
  'cimentier-applicateur': 2,
  couvreur: 2,
  electricien: 4,
  'unstallateur-de-systeme-de-securite': 3,
  ferblantier: 3,
  'monteur-mecanicien-vitrier': 3,
  'operateur-equip-lourd-classe-aa': 1,
  'operateur-pelles-classe-aa': 1,
  peintre: 3,
  platrier: 3,
  'poseur-systemes-interieurs': 3,
  'poseur-revetements-souples': 3,
  manoeuvre: 'occupation',
  'manoeuvre-specialise': 'occupation',
  'manoeuvre-en-maconnerie': 'occupation',
}

const statuts = {
  compagnon: 'Compagnon',
  'apprenti-1': 'Apprenti 1',
  'apprenti-2': 'Apprenti 2',
  'apprenti-3': 'Apprenti 3',
  'apprenti-4': 'Apprenti 4',
  occupation: 'Occupation',
}

const syndicats = {
  FTQ: 'FTQ-Construction',
  INTER: 'CPQMC (INTER)',
  CSD: 'CSD-Construction',
  CSN: 'CSN-Construction',
  SQC: 'SQC',
}

const database = [
  {
    __EMPTY: 1,
    A: 'Métiers',
    B: 'Statut',
    C: 'CSD',
    F: 'CSD',
    G: 'CSN',
    J: 'CSN',
    K: 'FTQ',
    N: 'FTQ',
    O: 'INTER',
    R: 'INTER',
    S: 'SQC',
  },
  {
    __EMPTY: 2,
    C: 'Hebdomadaire',
    D: 'Annuel',
    E: 'Différence',
    F: 'Diff. 4 ans',
    G: 'Hebdomadaire',
    H: 'Annuel',
    I: 'Différence',
    J: 'Diff. 4 ans',
    K: 'Hebdomadaire',
    L: 'Annuel',
    M: 'Différence',
    N: 'Diff. 4 ans',
    O: 'Hebdomadaire',
    P: 'Annuel',
    Q: 'Différence',
    R: 'Diff. 4 ans',
    S: 'Hebdomadaire',
    T: 'Annuel',
  },
  {
    __EMPTY: '3',
    A: 'Briqueteur-maçon',
    B: 'Compagnon',
    C: '23.33',
    D: '933.20',
    E: '425.20',
    F: '1700.80',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '20.00',
    L: '460.00',
    M: '-48.00',
    N: '-192.00',
    O: '17.00',
    P: '680.00',
    Q: '172.00',
    R: '688.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '4',
    A: 'Briqueteur-maçon',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.60',
    F: '286.40',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '15.00',
    L: '345.00',
    M: '-53.00',
    N: '-212.00',
    O: '14.00',
    P: '560.00',
    Q: '162.00',
    R: '648.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '5',
    A: 'Briqueteur-maçon',
    B: 'Apprenti 2',
    C: '16.75',
    D: '670.00',
    E: '266.00',
    F: '1064.00',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '15.00',
    L: '345.00',
    M: '-59.00',
    N: '-236.00',
    O: '15.00',
    P: '600.00',
    Q: '196.00',
    R: '784.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '6',
    A: 'Briqueteur-maçon',
    B: 'Apprenti 3',
    C: '20.04',
    D: '801.60',
    E: '397.60',
    F: '1590.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '15.00',
    L: '345.00',
    M: '-59.00',
    N: '-236.00',
    O: '16.00',
    P: '640.00',
    Q: '236.00',
    R: '944.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '7',
    A: 'Carreleur',
    B: 'Compagnon',
    C: '23.57',
    D: '942.80',
    E: '434.80',
    F: '1739.20',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '15.75',
    L: '630.00',
    M: '122.00',
    N: '488.00',
    O: '17.00',
    P: '680.00',
    Q: '172.00',
    R: '688.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '8',
    A: 'Carreleur',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '13.75',
    L: '550.00',
    M: '152.00',
    N: '608.00',
    O: '14.00',
    P: '560.00',
    Q: '162.00',
    R: '648.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '9',
    A: 'Carreleur',
    B: 'Apprenti 2',
    C: '17.08',
    D: '683.20',
    E: '279.20',
    F: '1116.80',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '13.75',
    L: '550.00',
    M: '146.00',
    N: '584.00',
    O: '15.00',
    P: '600.00',
    Q: '196.00',
    R: '784.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '10',
    A: 'Carreleur',
    B: 'Apprenti 3',
    C: '20.44',
    D: '817.60',
    E: '413.60',
    F: '1654.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '13.75',
    L: '550.00',
    M: '146.00',
    N: '584.00',
    O: '16.00',
    P: '640.00',
    Q: '236.00',
    R: '944.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '11',
    A: 'Charpentier-menuisier',
    B: 'Compagnon',
    C: '23.55',
    D: '942.00',
    E: '434.00',
    F: '1736.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '23.52',
    L: '940.80',
    M: '432.80',
    N: '1731.20',
    O: '12.30',
    P: '492.00',
    Q: '-16.00',
    R: '-64.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '12',
    A: 'Charpentier-menuisier',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '14.67',
    L: '586.80',
    M: '188.80',
    N: '755.20',
    O: '10.05',
    P: '402.00',
    Q: '4.00',
    R: '16.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '13',
    A: 'Charpentier-menuisier',
    B: 'Apprenti 2',
    C: '16.80',
    D: '672.00',
    E: '268.00',
    F: '1072.00',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '16.88',
    L: '675.20',
    M: '271.20',
    N: '1084.80',
    O: '10.05',
    P: '402.00',
    Q: '-2.00',
    R: '-8.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '14',
    A: 'Charpentier-menuisier',
    B: 'Apprenti 3',
    C: '20.20',
    D: '808.00',
    E: '404.00',
    F: '1616.00',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '20.2',
    L: '808.00',
    M: '404.00',
    N: '1616.00',
    O: '10.05',
    P: '402.00',
    Q: '-2.00',
    R: '-8.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '15',
    A: 'Cimentier-applicateur',
    B: 'Compagnon',
    C: '22.86',
    D: '914.40',
    E: '406.00',
    F: '1624.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '20.00',
    L: '460.00',
    M: '-48.00',
    N: '-192.00',
    O: '12.57',
    P: '502.80',
    Q: '-5.20',
    R: '-20.80',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '16',
    A: 'Cimentier-applicateur',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '15.00',
    L: '345.00',
    M: '-53.00',
    N: '-212.00',
    O: '9.22',
    P: '368.80',
    Q: '-29.20',
    R: '-116.80',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '17',
    A: 'Cimentier-applicateur',
    B: 'Apprenti 2',
    C: '19.66',
    D: '786.40',
    E: '382.40',
    F: '1529.60',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '15.00',
    L: '345.00',
    M: '-59.00',
    N: '-236.00',
    O: '10.89',
    P: '435.60',
    Q: '31.60',
    R: '126.40',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '18',
    A: 'Couvreur',
    B: 'Compagnon',
    C: '23.98',
    D: '959.20',
    E: '451.20',
    F: '1804.80',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '18.36',
    L: '734.40',
    M: '226.40',
    N: '905.60',
    O: '26.58',
    P: '1063.20',
    Q: '555.20',
    R: '2220.80',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '19',
    A: 'Couvreur',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '13.21',
    L: '528.40',
    M: '130.40',
    N: '521.60',
    O: '19.81',
    P: '792.40',
    Q: '394.40',
    R: '1577.60',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '20',
    A: 'Couvreur',
    B: 'Apprenti 2',
    C: '20.59',
    D: '823.60',
    E: '419.60',
    F: '1678.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '15.78',
    L: '631.20',
    M: '227.20',
    N: '908.80',
    O: '23.19',
    P: '927.60',
    Q: '523.60',
    R: '2094.40',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '21',
    A: 'Électricien',
    B: 'Compagnon',
    C: '23.80',
    D: '952.00',
    E: '444.00',
    F: '1776.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '26.63',
    L: '1065.38',
    M: '557.38',
    N: '2229.52',
    O: '33.11',
    P: '1324.40',
    Q: '816.00',
    R: '3264.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '22',
    A: 'Électricien',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '26.63',
    L: '1065.38',
    M: '667.38',
    N: '2669.52',
    O: '24.40',
    P: '976.00',
    Q: '578.00',
    R: '2312.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '23',
    A: 'Électricien',
    B: 'Apprenti 2',
    C: '14.84',
    D: '593.60',
    E: '189.60',
    F: '758.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '26.63',
    L: '1065.38',
    M: '661.38',
    N: '2645.52',
    O: '24.40',
    P: '976.00',
    Q: '572.00',
    R: '2288.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '24',
    A: 'Électricien',
    B: 'Apprenti 3',
    C: '17.08',
    D: '683.20',
    E: '279.20',
    F: '1116.80',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '26.63',
    L: '1065.38',
    M: '661.38',
    N: '2645.52',
    O: '24.40',
    P: '976.00',
    Q: '572.00',
    R: '2288.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '25',
    A: 'Électricien',
    B: 'Apprenti 4',
    C: '20.44',
    D: '817.60',
    E: '413.60',
    F: '1654.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '26.63',
    L: '1065.38',
    M: '661.38',
    N: '2645.52',
    O: '24.40',
    P: '976.00',
    Q: '572.00',
    R: '2288.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '26',
    A: 'Installateur de système de sécurité',
    B: 'Compagnon',
    C: '19.50',
    D: '780.00',
    E: '272.00',
    F: '1088.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '26.63',
    L: '1065.38',
    M: '557.38',
    N: '2229.52',
    O: '22.88',
    P: '915.04',
    Q: '407.04',
    R: '1628.16',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '27',
    A: 'Installateur de système de sécurité',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '26.63',
    L: '1065.38',
    M: '667.38',
    N: '2669.52',
    O: '17.08',
    P: '683.36',
    Q: '285.36',
    R: '1141.44',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '28',
    A: 'Installateur de système de sécurité',
    B: 'Apprenti 2',
    C: '14.07',
    D: '562.80',
    E: '158.80',
    F: '635.20',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '26.63',
    L: '1065.38',
    M: '661.38',
    N: '2645.52',
    O: '18.53',
    P: '741.28',
    Q: '337.28',
    R: '1349.12',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '29',
    A: 'Installateur de système de sécurité',
    B: 'Apprenti 3',
    C: '16.78',
    D: '671.20',
    E: '267.20',
    F: '1068.80',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '26.63',
    L: '1065.38',
    M: '661.38',
    N: '2645.52',
    O: '20.70',
    P: '828.16',
    Q: '424.16',
    R: '1696.64',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '30',
    A: 'Ferblantier',
    B: 'Compagnon',
    C: '23.80',
    D: '952.00',
    E: '444.00',
    F: '1776.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '23.20',
    L: '927.80',
    M: '419.80',
    N: '1679.20',
    O: '26.40',
    P: '1056.00',
    Q: '548.00',
    R: '2192.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '31',
    A: 'Ferblantier',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '14.24',
    L: '569.60',
    M: '171.60',
    N: '686.40',
    O: '17.44',
    P: '697.60',
    Q: '299.60',
    R: '1198.40',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '32',
    A: 'Ferblantier',
    B: 'Apprenti 2',
    C: '17.08',
    D: '683.20',
    E: '279.20',
    F: '1116.80',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '16.48',
    L: '659.20',
    M: '255.20',
    N: '1020.80',
    O: '19.68',
    P: '787.20',
    Q: '383.20',
    R: '1532.80',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '33',
    A: 'Ferblantier',
    B: 'Apprenti 3',
    C: '20.44',
    D: '817.60',
    E: '431.40',
    F: '1725.60',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '19.84',
    L: '793.60',
    M: '389.60',
    N: '1558.40',
    O: '23.03',
    P: '921.20',
    Q: '517.20',
    R: '423.60',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '34',
    A: 'Monteur-mécanicien vitrier',
    B: 'Compagnon',
    C: '23.39',
    D: '935.60',
    E: '427.60',
    F: '1710.40',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '23.99',
    L: '959.60',
    M: '1467.60',
    N: '5870.40',
    O: '23.99',
    P: '959.60',
    Q: '451.60',
    R: '1806.40',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '35',
    A: 'Monteur-mécanicien vitrier',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '15.19',
    L: '607.60',
    M: '209.60',
    N: '838.40',
    O: '15.19',
    P: '607.60',
    Q: '209.60',
    R: '838.40',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '36',
    A: 'Monteur-mécanicien vitrier',
    B: 'Apprenti 2',
    C: '16.79',
    D: '671.60',
    E: '267.60',
    F: '1070.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '17.39',
    L: '695.60',
    M: '291.60',
    N: '1166.40',
    O: '17.39',
    P: '695.60',
    Q: '291.60',
    R: '1166.40',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '37',
    A: 'Monteur-mécanicien vitrier',
    B: 'Apprenti 3',
    C: '20.09',
    D: '803.60',
    E: '399.60',
    F: '1598.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '20.69',
    L: '827.60',
    M: '423.60',
    N: '1694.40',
    O: '20.69',
    P: '827.60',
    Q: '423.60',
    R: '1694.40',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '38',
    A: '"Opérateur équip. lourd, classe AA"',
    B: 'Compagnon',
    C: '24.12',
    D: '964.80',
    E: '456.00',
    F: '1824.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '33.54',
    L: '1341.60',
    M: '833.60',
    N: '3334.40',
    O: '27.26',
    P: '1090.40',
    Q: '582.40',
    R: '2329.60',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '39',
    A: '"Opérateur équip. lourd, classe AA"',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '33.54',
    L: '1341.60',
    M: '943.60',
    N: '3774.40',
    O: '23.17',
    P: '926.88',
    Q: '528.88',
    R: '2115.52',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '40',
    A: '"Opérateur pelles, classe AA"',
    B: 'Compagnon',
    C: '25.50',
    D: '1020.00',
    E: '512.00',
    F: '2048.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '33.54',
    L: '1341.60',
    M: '833.60',
    N: '3334.40',
    O: '28.91',
    P: '1156.40',
    Q: '648.40',
    R: '2593.60',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '41',
    A: '"Opérateur pelles, classe AA"',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '33.54',
    L: '1341.60',
    M: '943.60',
    N: '3774.40',
    O: '24.56',
    P: '982.40',
    Q: '584.40',
    R: '2337.60',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '42',
    A: 'Peintre',
    B: 'Compagnon',
    C: '21.92',
    D: '876.80',
    E: '359.80',
    F: '1439.20',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '17.50',
    L: '700.00',
    M: '192.00',
    N: '768.00',
    O: '22.92',
    P: '916.80',
    Q: '408.80',
    R: '1635.20',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '43',
    A: 'Peintre',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '10.00',
    L: '400.00',
    M: '2.00',
    N: '8.00',
    O: '14.55',
    P: '582.00',
    Q: '184.00',
    R: '736.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '44',
    A: 'Peintre',
    B: 'Apprenti 2',
    C: '16.04',
    D: '641.60',
    E: '237.60',
    F: '950.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '14.90',
    L: '596.00',
    M: '192.00',
    N: '768.00',
    O: '16.64',
    P: '665.60',
    Q: '261.00',
    R: '1044.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '45',
    A: 'Peintre',
    B: 'Apprenti 3',
    C: '19.18',
    D: '767.20',
    E: '363.20',
    F: '1452.80',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '14.90',
    L: '596.00',
    M: '192.00',
    N: '768.00',
    O: '19.78',
    P: '791.20',
    Q: '387.20',
    R: '1548.80',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '46',
    A: 'Plâtrier',
    B: 'Compagnon',
    C: '22.75',
    D: '910.00',
    E: '402.00',
    F: '1608.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '20.00',
    L: '460.00',
    M: '-48.00',
    N: '-192.00',
    O: '12.50',
    P: '500.00',
    Q: '-8.00',
    R: '-32.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '47',
    A: 'Plâtrier',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '15.00',
    L: '345.00',
    M: '-53.00',
    N: '-212.00',
    O: '8.05',
    P: '322.00',
    Q: '-76.00',
    R: '-304.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '48',
    A: 'Plâtrier',
    B: 'Apprenti 2',
    C: '16.34',
    D: '653.60',
    E: '249.60',
    F: '998.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '15.00',
    L: '345.00',
    M: '-59.00',
    N: '-236.00',
    O: '9.17',
    P: '366.80',
    Q: '-37.20',
    R: '-148.80',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '49',
    A: 'Plâtrier',
    B: 'Apprenti 3',
    C: '19.55',
    D: '782.00',
    E: '378.00',
    F: '1512.00',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '15.00',
    L: '345.00',
    M: '-59.00',
    N: '-236.00',
    O: '10.84',
    P: '433.60',
    Q: '33.60',
    R: '134.40',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '50',
    A: 'Poseur systèmes intérieurs',
    B: 'Compagnon',
    C: '23.52',
    D: '940.80',
    E: '432.80',
    F: '1731.20',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '23.52',
    L: '940.80',
    M: '432.80',
    N: '1731.20',
    O: '12.30',
    P: '492.00',
    Q: '-16.00',
    R: '-64.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '51',
    A: 'Poseur systèmes intérieurs',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '14.67',
    L: '586.80',
    M: '188.80',
    N: '755.20',
    O: '10.05',
    P: '402.00',
    Q: '4.00',
    R: '16.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '52',
    A: 'Poseur systèmes intérieurs',
    B: 'Apprenti 2',
    C: '16.88',
    D: '675.20',
    E: '271.20',
    F: '1084.80',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '16.88',
    L: '675.20',
    M: '271.20',
    N: '1084.80',
    O: '10.05',
    P: '402.00',
    Q: '-2.00',
    R: '-8.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '53',
    A: 'Poseur systèmes intérieurs',
    B: 'Apprenti 3',
    C: '20.20',
    D: '808.00',
    E: '404.00',
    F: '1616.00',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '20.2',
    L: '808.00',
    M: '404.00',
    N: '1616.00',
    O: '10.05',
    P: '402.00',
    Q: '-2.00',
    R: '-8.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '54',
    A: 'Poseur de revêtemtents souples',
    B: 'Compagnon',
    C: '23.04',
    D: '921.60',
    E: '413.00',
    F: '1652.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '23.52',
    L: '940.80',
    M: '432.80',
    N: '1731.20',
    O: '12.30',
    P: '492.00',
    Q: '-16.00',
    R: '-64.00',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '55',
    A: 'Poseur de revêtemtents souples',
    B: 'Apprenti 1',
    C: '11.74',
    D: '469.60',
    E: '71.65',
    F: '286.60',
    G: '10.00',
    H: '400.00',
    I: '2.00',
    J: '8.00',
    K: '14.67',
    L: '586.80',
    M: '188.80',
    N: '755.20',
    O: '10.05',
    P: '402.00',
    Q: '4.00',
    R: '16.00',
    S: '9.95',
    T: '398.00',
  },
  {
    __EMPTY: '56',
    A: 'Poseur de revêtemtents souples',
    B: 'Apprenti 2',
    C: '16.55',
    D: '662.00',
    E: '258.00',
    F: '1032.00',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '16.88',
    L: '675.20',
    M: '271.20',
    N: '1084.80',
    O: '10.05',
    P: '402.00',
    Q: '-2.00',
    R: '-8.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '57',
    A: 'Poseur de revêtemtents souples',
    B: 'Apprenti 3',
    C: '19.79',
    D: '791.60',
    E: '387.60',
    F: '1550.40',
    G: '10.00',
    H: '400.00',
    I: '-4.00',
    J: '-16.00',
    K: '20.2',
    L: '808.00',
    M: '404.00',
    N: '1616.00',
    O: '10.05',
    P: '402.00',
    Q: '-2.00',
    R: '-8.00',
    S: '10.10',
    T: '404.00',
  },
  {
    __EMPTY: '58',
    A: 'Manoeuvre',
    B: 'Occupation',
    C: '19.15',
    D: '766.00',
    E: '258.00',
    F: '1032.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '28.40',
    L: '1136.00',
    M: '628.00',
    N: '2512.00',
    O: '25.67',
    P: '1026.80',
    Q: '518.80',
    R: '2075.20',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '59',
    A: 'Manoeuvre spécialisé',
    B: 'Occupation',
    C: '19.43',
    D: '777.20',
    E: '269.20',
    F: '1076.80',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '28.85',
    L: '1154.00',
    M: '646.00',
    N: '2584.00',
    O: '26.04',
    P: '1041.60',
    Q: '533.60',
    R: '2134.40',
    S: '12.70',
    T: '508.00',
  },
  {
    __EMPTY: '60',
    A: 'Manoeuvre en maçonnerie',
    B: 'Occupation',
    C: '19.90',
    D: '796.00',
    E: '288.00',
    F: '1152.00',
    G: '12.00',
    H: '480.00',
    I: '-28.00',
    J: '-112.00',
    K: '29.60',
    L: '1184.00',
    M: '676.00',
    N: '2704.00',
    O: '26.65',
    P: '1066.00',
    Q: '558.00',
    R: '2232.00',
    S: '12.70',
    T: '508.00',
  },
]

const refs = {
  CSD: 'F',
  CSN: 'J',
  FTQ: 'N',
  INTER: 'R',
  SQC: 'T',
}

const refsNegativeNumber = {
  CSD: 'E',
  CSN: 'I',
  FTQ: 'M',
  INTER: 'Q',
  SQC: 'T',
}

module.exports = {
  metiers,
  metiersStatutLoop,
  statuts,
  syndicats,
  database,
  refs,
  refsNegativeNumber,
}
